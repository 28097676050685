body.cms-blogblank {
  .wrapper {
    .page {
      .main.container {
        padding: 0 15px;
        .col-main {
          padding-top: 35px;
          color: $olive;
          .titelCard {
            min-height: 520px;
            h1 {
              p {
                margin-right: 30px;
                margin-left: 15px;
                position: relative;
                font-family: $font-ultralight;
                font-size: 32px;
                color: $olive;
              }
            }
            .annotation {
              margin-right: 30px;
              margin-left: 15px;
              font-size: 12px;
              position: absolute;
              bottom: 30px;
              border-bottom: 2px solid;
              padding-bottom: 15px;
            }
          }
          .blogCard {
            padding-right: 30px;
            padding-left: 30px;
            min-height: 550px;
            @media (max-width: 992px) and (min-width: 515px) {
              padding-bottom: 20px;
            }
            a {
              text-decoration: none;
              color: $olive;
              cursor: pointer;

              .cardBorder {
                min-height: 490px;
                max-height: 100%;
                box-shadow: 0 2px 10px rgba(135, 135, 135, $boxshadow-opacity);
                overflow: hidden;
                position: relative;
                &:hover, &:focus, &:active {
                  box-shadow: 0 2px 10px rgba(135, 135, 135, $boxshadow-opacity + 0.3);
                  }
                .img-responsive {
                  width: 100%;
                  @media (min-width: 992px) {
                    min-height: 220px;
                    max-height: 220px;
                    max-width: 350px;
                  }
                }
              }
              .textCard {
                min-height: 132px;
                max-height: 100%;
                padding: 20px;
                h3 {
                  margin-top: 0px;
                  margin-bottom: 8px;
                  font-size: 20px;
                }
                p {
                  height: 132px;
                  font-size: 14px;
                }
                .read-more {
                  position: absolute;
                  bottom: 20px;
                  font-size: 13px;
                  color: $olive-dark;
                  &:hover, &:focus, &:active {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        @media(min-width: 992px) {
          padding: 0 30px;
        }
      }
    }
  }
  .pagination {
    clear: both;
    margin-right: 30px;
    margin-top: 0px;
    position: relative;
    font-size: 11px;
    line-height: 13px;
    float: right;
  }

  .pagination span, .pagination a {
    @media(max-width: 992px) {
        font-size: 18px;
        line-height: 37px;
        margin-right: 10px;
      min-width: 41px;
      }
    display: block;
    float: left;
    margin: 2px 2px 2px 0;
    padding: 6px 9px 5px 9px;
    text-decoration: none;
    width: auto;
    color: #fff;
    background: $olive;
    -webkit-transition: background .15s ease-in-out;
    -moz-transition: background .15s ease-in-out;
    -ms-transition: background .15s ease-in-out;
    -o-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out;
    min-width: 30px;
    text-align: center;

  }

  .pagination a:hover {
    color: #fff;
    background: $olive-light;
  }

  .pagination .current {
    padding: 6px 9px 5px 9px;
    background: $olive-dark;
    color: #fff;
  }
}