@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-Black.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Black.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Bold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-ExtranBold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-ExtraBold.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-Hairline.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Hairline.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-SemiBold.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src:    url("../fonts/Montserrat-UltraLight.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-UltraLight.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat-Regular";
  src:    url("../fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Black";
  src:    url("../fonts/Montserrat-Black.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Black.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src:    url("../fonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src:    url("../fonts/Montserrat-ExtraBold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Hairline";
  src:    url("../fonts/Montserrat-Hairline.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Hairline.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Light";
  src:    url("../fonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-Light.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src:    url("../fonts/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-UltraLight";
  src:    url("../fonts/Montserrat-UltraLight.eot?#iefix") format('embedded-opentype'),
          url("../fonts/Montserrat-UltraLight.otf") format("opentype");
}