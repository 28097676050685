body.cms-blogblank {
  .btn-success {
    border: none;
    border-radius: 0px;
    background-color: $olive-dark;
    :hover {
      background-color: $olive;
    }
  }
  .container {
    .col-main {
      .entry-content {
        .col-md-4 {
          &.bottomblock {
            p {
                opacity: 0.4;
                font-size: 11px;
            }
          }
          p {
            color: $olive;
          }
          header {
            .entry-title {
              margin-top: 0px;
              margin-bottom: 0px;
              padding-bottom: 35px;
              font-family: $font-ultralight;
              border-bottom: 1px solid;
            }
          }
          .entry-date {
            opacity: 0.4;
            font-size: 11px;
            margin-top: 5px;
            margin-bottom: 35px;
          }
        }
        .img-responsive {
          margin-bottom: 63px;
        }
        .pictures {
          padding-right: 0px;
          .img-responsive {
            width: 100%;
            margin-bottom: 15px;
          }
          .lightbox {
            /** Wrapper Hidden */
            display: none;

            /** Styling */
            position: fixed;
            z-index: 999;
            width: 100%;
            height: 100%;
            text-align: center;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.8);
          }

          .lightbox img {
            /** Big image */
            max-width: 90%;
            max-height: 80%;
            margin-top: 2%;
          }

          .lightbox:target {
            outline: none;
            display: block;
          }
        }
        .col-md-4 {
          margin-bottom: 25px;
          .col-md-6 {
            margin-bottom: 15px;
          }
        }

        @media(min-width: 768px) {
          .pictures {
            .img-responsive {
              width: initial;
            }
          }
        }
      }
    }
  }
}
